.WhatsAppBroadCastField {
  color: #00a570;
  cursor: pointer;
}

.MailOutlineIcon {
  color: #0052c9;
  cursor: pointer;
}

.ActionField {
  color: #272522;
}

.imageStats {
  display: block;
  margin: auto;
}

.tag {
  margin-right: 9px;
}
.disabledIcon {
  opacity: 0.5;
  color: #00a570;
  cursor: not-allowed;
  pointer-events: all !important;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: all !important;
}

.modalLabel {
  width: 378px;
}

.disabledIconMail {
  cursor: not-allowed;
  pointer-events: all !important;
  opacity: 0.5;
  color: #0052c9;
}

.actionIcon,
.blueAction,
.redAction {
  width: 24px !important;
  height: 24px !important;
}

.blueAction,
.blueAction path {
  fill: #493ab1;
}

.actionIcon,
.actionIcon path {
  fill: #272522;
}

.redAction,
.redAction path {
  fill: #aa2822;
}

.blueButton {
  color: #493ab1 !important;
}

.redButton {
  color: #aa2822 !important;
}

.blackButton {
  color: #272522 !important;
}

.MenuOptions {
  width: 201px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  color: #272522;
}

.MenuItemViewEdit {
  color: #493ab1;
}

.MenuItemDelete {
  color: #b83b42;
}

.MenuItems {
  margin-top: 5px;
  cursor: pointer;
  padding: 5px 10px;
}

.MenuOptions :hover {
  background-color: #f0efee !important;
}

.modalHeader {
  background: linear-gradient(
    90deg,
    #493ab1 0%,
    #1f99aa 72.27%,
    #009ba5 136.58%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.flex {
  display: flex;
}

.tooltipContainer {
  width: 250px;
  padding: 12px;
}

.tooltipButtonContainer {
  margin-left: 50%;
  margin-top: 12px;
}

.tooltipIcon {
  margin-left: 10px;
}

.helpIcon {
  color: #b8b4ad;
  transform: scale(0.8);
}

.tooltipButton {
  /* float: right; */
  width: 71px !important;
  height: 32px !important;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
}
.tooltipButton span {
  font-weight: 500;
  font-size: 14px;
}

.point {
  cursor: pointer;
  display: flex;
  word-break: break-word;
}

.modalContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.modalContainer h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  letter-spacing: 0.02em;

  color: #272522;
}

.modalContainer p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  letter-spacing: 0.02em;

  color: #272522;
}
