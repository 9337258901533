.contentModal {
  padding: 16px 0px 0px 0px;
}

.contentModal p {
  color: #27252299;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.modalRadioGroup {
  margin-top: 25.5px;
}

@media (max-width: 768px) {
  .contentModal {
    padding-top: 12px 0px 0px 0px;
  }
}
