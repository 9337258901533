.parentContainer {
  margin-left: -5px;
}

.container {
  overflow: hidden;
}

.viewContainer {
  overflow: hidden;
  margin-left: 24px !important;
}

.labelMuted {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.3);
  margin-left: 24px;
  padding-top: 12px;
}

.segmentName label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 24px;
}

.segmentName span {
  color: rgba(0, 0, 0, 0.3);
  height: 12px !important;
  width: 12px !important;
  margin-left: 8px;
}

.arrowRightIcon {
  color: #0000004d;
  margin-left: 6px;
  padding-bottom: 4px;
}

.conditionRemove {
  color: #aa2822;
  padding-left: 2px;
  margin: 5px;
  cursor: pointer;
}

.conditionDuplicate {
  color: #1c1b1f;
  padding-left: 2px;
  margin: 5px;
  cursor: pointer;
}

.conditionEdit {
  color: #1c1b1f;
  padding-left: 2px;
  margin-left: 10px;
  cursor: pointer;
}

.select {
  line-height: 15 !important;
}

/* ----------------------- */

.condition_container {
  padding: 16px 0px 16px 24px;
}

.labelMuted_1 {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.3);
  padding-bottom: 12px;
}

.labelMuted_1 span {
  color: rgba(0, 0, 0, 0.3);
  height: 12px !important;
  width: 12px !important;
  margin-left: 8px;
}

.segmentName_1 label {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
}

.toolTipIcon {
  font-size: 16px;
  line-height: 22px;
  transform: scale(0.7);
}

.condtionsContainer {
  background: #fafafa;
  overflow: hidden;
  margin-left: -24px;
  height: 100%;
}

.condtionsContainer label {
  padding: 32px 24px 24px 24px;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: rgba(0, 0, 0, 0.3);
}

.buttonText {
  margin-left: 5px;
  height: 24px;
  display: flex;
  align-items: center;
}

.addButtonContainer {
  padding-top: 12px;
  padding-bottom: 24px;
  margin-bottom: 32px;
  width: 650px;
  display: flex;
  justify-content: center;
  padding-right: 10px;
}

.boldText {
  font-weight: 600;
}

.conditionCard {
  padding: 24px 0px 24px 24px;
  width: 650px;
}

.blueText {
  color: #493ab1;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
}

.verticalDot {
  position: absolute;
  right: 32px;
}

.operatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.operatorContainer_start {
  width: 650px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashedBorder {
  border-left: 1.5px dashed rgba(39, 37, 34, 0.3);
  height: 14px;
  margin-top: 4px;
  margin-bottom: 2.3px;
}

.operatorValue {
  color: rgba(39, 37, 34, 0.6);
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.accountTreeIcon {
  padding-bottom: 4px;
  padding-top: 4px;
}

.segmentConditionText {
  color: #0000004d;
}

@media screen and (max-width: 780px) {
  .conditionCard {
    width: 95%;
  }

  .operatorContainer {
    left: 50%;
    margin-left: 0;
  }

  .operatorContainer_start {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .addButtonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .buttonDesign {
    position: absolute;
    left: 0;
    top: 10px;
    font-weight: 500;
  }

  .buttonDesign_save {
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 500;
  }

  .mobileTableWrapper {
    display: grid !important;
    grid-template-columns: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .verticalDot {
    position: absolute;
    right: 18px;
  }
}

/* ------------------------------------ */

.button_container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  height: 60px;
  border-top: 0.5px solid #e7e6e4;
}

.previewTable {
  margin-top: 10px;
  margin-bottom: 10px;
}

.previewTableWithScroll {
  padding-right: 16px !important;
}

.desktop_modal_paper {
  max-width: 784px !important;
  width: 100%;
}

.mobileTableWrapper {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
}

.buttonDesign {
  position: absolute;
  right: 153px;
  top: 10px;
  font-weight: 500;
}

.buttonDesign_save {
  position: absolute;
  right: 24px;
  top: 10px;
  font-weight: 500;
}

.formDesc {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: rgba(39, 37, 34, 0.6);
}

.formHeader {
  margin-top: 20px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #272522;
}

.formInfo {
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: rgba(39, 37, 34, 0.6);
}

.customerInfo {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: rgba(39, 37, 34, 0.6);
}

.formContainer {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
}

.formTitle {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
  line-height: 20px;
}

.modalPaperClass {
  max-width: 516px;
}

.input {
  margin-bottom: 15px;
}

.icon {
  width: 100%;
  background-color: #edebf7;
}

.toggle_root {
  padding: 4px !important;
  display: flex;
  justify-content: center;
  color: #ffffff;
  background: #eeecf9;
  border-radius: 16px !important;
}

.button_root {
  text-transform: none !important;
  border-radius: 24px !important;
  border: 0px !important;
  color: #493ab1 !important;
  height: 25px;
  width: 50px;
  margin: 3px;
}

.menu_item_selected {
  border-radius: 24px !important;
  border: 0;
  color: #ffffff !important;
  background-color: #493ab1 !important;
  margin: 3px;
}

loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 100%;
}

/* ---------Segmentation Form----------- */

.modalPaperClassName {
  max-width: 428px !important;
  min-width: 428px;
  overflow: visible !important;
}

/* custom style for exly modal mobile footer */
.mobileModalFooter {
  left: -23px !important;
}

.formInputClassName {
  padding: 20px 0px;
  min-height: 250px;
}

.inputClassName {
  margin-bottom: 12px;
}

.inputGhost {
  background: transparent;
  overflow: visible;
  min-width: 196px;
}

.searchByDropdown {
  height: 40px;
}

.headerContainer {
  padding: 0 24px;
  height: 75.23px;
}

@media (max-width: 767px) {
  .modalPaper {
    overflow-y: visible !important;
  }
}
